import './header.css';

// si on trouve un element avec la class has-full-header alors utilise le gros header qui retréci quand on scroll
const fullHeader = document.querySelector('.has-full-header') != null;
if (fullHeader) {
  const headerElement = document.getElementById('header');

  // Sur un scroll on modifie la class du header
  document.addEventListener(
    'scroll',
    () => {
      const headerTopPartHeight = getHeaderTopPartHeight(headerElement);
      const enableTinyMode = window.scrollY > headerTopPartHeight;
      headerElement.classList.toggle('tiny', enableTinyMode);
      document.documentElement.classList.toggle('header-tiny', enableTinyMode);
      initStickyHeader(headerElement);
    },
    { passive: true }
  );

  window.addEventListener('resize', () => initStickyHeader(headerElement));
}

function initStickyHeader(headerElement) {
  const headerTopPartHeight = getHeaderTopPartHeight(headerElement);
  headerElement.style.top = '-' + headerTopPartHeight + 'px';

  headerElement.classList.toggle('tiny', window.scrollY > headerTopPartHeight);
}

function getHeaderTopPartHeight(headerElement) {
  let stickyElement;
  if (window.matchMedia('(max-width: 767px)').matches) {
    stickyElement = headerElement.querySelector('.header-bandeau');
  } else {
    stickyElement = headerElement.querySelector('#header-bandeau-search-bar');
  }

  return headerElement.offsetHeight - stickyElement.offsetHeight;
}

/* Dropdown */
const dropdownContent = document.getElementById('dropdownContent');
const dropdownBtn = document.getElementById('dropdownBtn');

// ouvre ou ferme le dropdown
dropdownBtn.addEventListener('click', function () {
  const open = dropdownContent.classList.toggle('show');
  if (open) {
    setTimeout(() => window.addEventListener('click', closeDropdown), 0);
  }
});

const inputOption = document.getElementById('searchInputOption');
dropdownContent.querySelectorAll('button').forEach((button) => {
  // change la valeur du dropdown
  button.addEventListener('click', function () {
    const value = button.dataset.value;

    dropdownBtn.innerHTML = button.innerHTML;

    inputOption.value = value;
  });
});

function closeDropdown() {
  if (dropdownContent.classList.contains('show')) {
    dropdownContent.classList.remove('show');
  }

  window.removeEventListener('click', closeDropdown);
}
